import { Injectable } from '@angular/core';
import { SettingsState } from '@shared/states/settings.state';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { JiraCreateIssueDTO } from '@shared/dto/gateway-secured/jira-create-issue-dto';
import { JiraIssueDTO } from '@shared/dto/gateway-secured/models';

@Injectable({
  providedIn: 'root',
})
export class IssuesService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public createBug(data: JiraCreateIssueDTO): Observable<boolean> {
    return this.http
      .post(`${this.settingsState.apiPath}/issues`, data, {
        responseType: 'text',
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public getByEmail(
    email: string = localStorage.getItem(LocalStorageConstants.Email),
  ): Observable<JiraIssueDTO[]> {
    return this.http.get(`${this.settingsState.apiPath}/issues/email`, {
      params: {
        email,
        index: '0',
        size: '9999999',
      },
    }) as Observable<JiraIssueDTO[]>;
  }
}
