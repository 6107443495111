import { Injectable } from '@angular/core';
import { PortfoliosService } from '@api/portfolios.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AccountsService } from '@api/accounts.service';
import { isNotNull } from '@shared/base/core';
import { PortfolioDTO } from '@shared/dto/positions/models';
import { map, tap } from 'rxjs/operators';

import { AccountDTO } from '@shared/dto/accounts/account-dto';
import { PeriodEnum } from '@shared/types/period-enum';

export enum PORTFOLIO_URL_PARAM {
  PORTFOLIO_ID = 'portfolioId',
  ACCOUNT_ID = 'accountId',
}

@Injectable({
  providedIn: 'root',
})
export class PortfolioGlobalState {
  public period$ = new BehaviorSubject<PeriodEnum>(PeriodEnum.YEAR);
  public portfolio$ = new BehaviorSubject<PortfolioDTO>(null);
  public account$ = new BehaviorSubject<AccountDTO>(null);

  constructor(
    private portfoliosService: PortfoliosService,
    private accountsService: AccountsService,
  ) {}

  public setAccount(account: string | AccountDTO): void {
    this.setAccount$(account).subscribe();
  }

  public setAccount$(account: string | AccountDTO): Observable<boolean> {
    if (typeof account === 'string') {
      this.setPortfolio(null);
      this.setParamToUrl(PORTFOLIO_URL_PARAM.ACCOUNT_ID, account as string);

      return this.accountsService.getOne(account as string).pipe(
        tap((data) => this.account$.next(data as AccountDTO)),
        map((data) => !!data),
      );
    } else if (isNotNull(account)) {
      this.setPortfolio(null);
      this.setParamToUrl(PORTFOLIO_URL_PARAM.ACCOUNT_ID, account?.id);
      this.account$.next(account);

      return of(true);
    } else {
      this.setParamToUrl(PORTFOLIO_URL_PARAM.ACCOUNT_ID, null);
      this.account$.next(null);

      return of(true);
    }
  }

  public setPortfolio(portfolio: string | PortfolioDTO): void {
    this.setPortfolio$(portfolio).subscribe();
  }

  public setPortfolio$(portfolio: string | PortfolioDTO): Observable<boolean> {
    if (typeof portfolio === 'string') {
      this.setAccount(null);
      this.setParamToUrl(PORTFOLIO_URL_PARAM.PORTFOLIO_ID, portfolio as string);

      return this.portfoliosService.getPortfolioById(portfolio as string).pipe(
        tap((data) => this.portfolio$.next(data)),
        map((data) => !!data),
      );
    } else if (isNotNull(portfolio)) {
      this.setAccount(null);
      this.setParamToUrl(PORTFOLIO_URL_PARAM.PORTFOLIO_ID, portfolio.id);
      this.portfolio$.next(portfolio);

      return of(true);
    } else {
      this.setParamToUrl(PORTFOLIO_URL_PARAM.PORTFOLIO_ID, null);
      this.portfolio$.next(null);

      return of(true);
    }
  }

  public getPortfolioIdFromUrl(): string {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(PORTFOLIO_URL_PARAM.PORTFOLIO_ID);
  }

  public getAccountIdFromUrl(): string {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(PORTFOLIO_URL_PARAM.ACCOUNT_ID);
  }

  private setParamToUrl(parameterName: PORTFOLIO_URL_PARAM, value: string): void {
    if (window.location.pathname.startsWith('/detail')) {
      return;
    }

    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);

      if (!value) {
        searchParams.delete(parameterName);
      } else {
        searchParams.set(parameterName, value);
      }

      const restParams = searchParams.toString();

      if (restParams.length) {
        const newRelativePathQuery = window.location.pathname + '?' + restParams;
        history.pushState(null, '', newRelativePathQuery);
      } else {
        history.pushState(null, '', window.location.pathname);
      }
    }
  }
}
