import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositionInformationComponent } from './position-information/position-information.component';
import { GridModule } from '@ui/grid/grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { PositionPrivateEquityInformationComponent } from './position-private-equity-information/position-private-equity-information.component';
import { PositionRealEstateInformationComponent } from './position-real-estate-information/position-real-estate-information.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SiteInfoOutletComponent } from './site-info-outlet/site-info-outlet.component';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { GetSiteInfoPipe } from './get-site-info.pipe';
import { SvgModule } from '@ui/svg/svg.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    GridModule,
    ButtonsModule,
    SvgModule,
    PipesModule,
    DirectivesModule,
  ],
  declarations: [
    PositionInformationComponent,
    PositionPrivateEquityInformationComponent,
    PositionRealEstateInformationComponent,
    DisclaimerComponent,
    SiteInfoOutletComponent,
    GetSiteInfoPipe,
  ],
  exports: [
    PositionInformationComponent,
    PositionPrivateEquityInformationComponent,
    PositionRealEstateInformationComponent,
    DisclaimerComponent,
    SiteInfoOutletComponent,
    GetSiteInfoPipe,
  ],
})
export class SiteInfoModule {}
