import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ExcelTableRequestDTO } from '@shared/dto/gateway-secured/models';

@Injectable({
  providedIn: 'root',
})
export class FileLoaderService {
  private r: Renderer2;
  private readonly fileSaverId = 'app-file-saver';

  /**
   * MIME types from link
   *
   * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   */
  private readonly MIME_MAP: { [ext: string]: string } = {
    txt: 'text/plain',
    xml: 'text/xml',
    csv: `text/csv`,
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    json: 'application/json',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
  };

  private MIME_TO_EXTENSION_MAP: { [mime: string]: string } = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
  ) {
    this.r = this.rendererFactory.createRenderer(null, null);

    Object.entries(this.MIME_MAP).forEach(([ext, mime]) => {
      this.MIME_TO_EXTENSION_MAP[mime] = ext;
    });

    this.init();
  }

  private init(): void {
    const aElement = this.document.createElement('a');
    aElement.setAttribute('id', this.fileSaverId);
    this.document.querySelector('body').appendChild(aElement);
    this.r.setStyle(aElement, 'display', 'none');
  }

  public getFileSaverElement(): HTMLLinkElement {
    return this.document.getElementById(this.fileSaverId) as HTMLLinkElement;
  }

  public genFileMIME(fileName?: string): string {
    if (!fileName || fileName.lastIndexOf('.') === -1) {
      return 'text/plain';
    }

    return this.MIME_MAP[this.getFileExtensionFromName(fileName)] || 'application/octet-stream';
  }

  public getFileNameWithouExtension(fileName: string): string {
    const index = fileName.lastIndexOf('.');

    return index !== -1 ? fileName.substr(0, index) : fileName;
  }

  public getFileExtensionFromName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  public getFileExtensionFromMIME(mime: string): string {
    return this.MIME_TO_EXTENSION_MAP[mime] || '';
  }

  public saveBlob(blob: Blob, fileName: string, nameIsFull: boolean = false): void {
    const aElement = this.getFileSaverElement();
    const mime = this.genFileMIME(fileName) || blob.type;
    aElement.href = URL.createObjectURL(new Blob([blob], { type: mime }));

    if (nameIsFull) {
      aElement.setAttribute('download', `${fileName}`);
    } else {
      aElement.setAttribute(
        'download',
        `${this.getFileNameWithouExtension(fileName)}.${this.getFileExtensionFromMIME(mime)}`,
      );
    }

    aElement.setAttribute('target', '_blank');
    aElement.click();
  }

  public loadAndSave(requestUrl: string, fileName?: string): void {
    this.http
      .get(requestUrl, { observe: 'response', responseType: 'blob' })
      .pipe(catchError(() => of(null as HttpResponse<Blob>)))
      .subscribe((res) => this.saveBlobRequestData(res, fileName));
  }

  public loadAndSaveByPost(
    requestUrl: string,
    body?: unknown,
    fileName?: string,
  ): Observable<void> {
    return this.http.post(requestUrl, body, { observe: 'response', responseType: 'blob' }).pipe(
      tap((res) => this.saveBlobRequestData(res, fileName)),
      map(() => null),
      catchError(() => of(null)),
    );
  }

  public saveBlobRequestData(
    res: HttpResponse<Blob>,
    fileName?: string,
    fileNameIsFull: boolean = false,
  ): void {
    if (res) {
      let externalFileName = res.headers.get('content-disposition');

      if (!fileName && externalFileName) {
        externalFileName = decodeURIComponent(externalFileName);
        if (externalFileName) {
          externalFileName = externalFileName
            .replace('attachment; filename=', '')
            .replace(/"/g, '');
        }

        const findNameWithEncoding = externalFileName.match("filename\\*=.*''(.*)");

        if (findNameWithEncoding) {
          externalFileName =
            findNameWithEncoding.length > 0 ? findNameWithEncoding[1] : findNameWithEncoding[0];
        }
      }

      this.saveBlob(res.body, fileName || externalFileName, fileNameIsFull);
    }
  }

  public loadAndSaveBySearch(
    url: string,
    searchRequest: ExcelTableRequestDTO,
    fileName: string,
    params?: { [param: string]: string[] | string | boolean },
  ): void {
    this.http
      .post(url, searchRequest, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(catchError(() => of(null as HttpResponse<Blob>)))
      .subscribe((res) => {
        if (res) {
          this.saveBlob(res.body, fileName);
        }
      });
  }

  public convertB64ToBlob(
    base64Data: string,
    contentType: string = '',
    sliceSize: number = 512,
  ): Blob {
    const byteCharacters = atob(
      base64Data.includes('base64') ? base64Data.split(',')[1] : base64Data,
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public getMaxUploadSizeInMb(maxUploadSize: number): number {
    const maxUploadSizeInMb = maxUploadSize / 1024 / 1024;

    return +maxUploadSizeInMb.toFixed(1);
  }

  public compressImage(
    originalImg: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
  ): Observable<Blob> {
    const subject$ = new Subject<Blob>();
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => subject$.next(blob), 'image/jpeg');
    };

    img.onerror = () => {
      subject$.next(null);
    };

    img.src = originalImg.src;

    return subject$.asObservable();
  }
}
