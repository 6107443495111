import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LayoutComponent } from './layout.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { FixedControlsModule } from '@components/fixed-controls/fixed-controls.module';
import { SvgModule } from '@ui/svg/svg.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DialogModule } from '@ui/dialog/dialog.module';
import { SvgRegistryService } from '@ui/svg/svg-registry.service';
import { NotificationModule } from '@ui/notification/notification.module';
import { Icons } from '@shared/constants/icons';
import { SiteInfoModule } from '@shared/components/site-info/site-info.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    DirectivesModule,
    PipesModule,
    FixedControlsModule,
    SiteInfoModule,
    DirectivesModule,
    DialogModule,
    SvgModule,
    NotificationModule,
  ],
  declarations: [FooterComponent, NavbarComponent, LayoutComponent, ProfileMenuComponent],
  exports: [FooterComponent, LayoutComponent],
})
export class LayoutModule {
  constructor(private svgRegistryService: SvgRegistryService) {
    this.svgRegistryService.preload(Icons.Edit_24);
  }
}
