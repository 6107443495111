import { Pipe, PipeTransform } from '@angular/core';
import { GetThousandValueWithSuffixPipe } from '@shared/pipes/get-thousand-value-with-suffix.pipe';
import { NumberPipe } from '@shared/pipes/number.pipe';

@Pipe({
  name: 'appGetTwelveDataValueWithSuffix',
})
export class GetTwelveDataValueWithSuffixPipe implements PipeTransform {
  private GET_THOUSAND_VALUE_WITH_SUFFIX_PIPE = new GetThousandValueWithSuffixPipe();
  private NUMBER_PIPE = new NumberPipe();

  constructor() {}

  public transform(value: string | number, title: string): string {
    const key = title.replace(/ /g, '_');
    switch (key) {
      case 'market_capitalization':
      case 'enterprise_value':
      case 'trailing_pe':
      case 'forward_pe':
      case 'peg_ratio':
      case 'price_to_sales_ttm':
      case 'price_to_book_mrq':
      case 'enterprise_to_revenue':
      case 'enterprise_to_ebitda': {
        const v = this.GET_THOUSAND_VALUE_WITH_SUFFIX_PIPE.transform(+value);
        return `${this.NUMBER_PIPE.transform(v.thousandValue)}${v.suffix}`;
      }

      default:
        return value.toString();
    }
  }
}
