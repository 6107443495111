import { Pipe, PipeTransform } from '@angular/core';
import { DateRangePeriod } from '../daterange-picker.types';
import { DateHelper } from '@shared/helpers/date-helper.service';

@Pipe({
  name: 'canShowRangeToggle',
})
export class CanShowRangeTogglePipe implements PipeTransform {
  private today = this.dateHelper.now();

  constructor(private dateHelper: DateHelper) {}

  public transform(value: DateRangePeriod, minDate?: Date, maxDate?: Date): boolean {
    if (!minDate && !maxDate) {
      return true;
    }

    let range: { start: Date; end: Date };

    switch (value) {
      case DateRangePeriod.Last7Days:
      case DateRangePeriod.Next7Days:
      case DateRangePeriod.ThisMonth:
      case DateRangePeriod.ThreeMonths:
      case DateRangePeriod.YTD:
      case DateRangePeriod.Year:
      case DateRangePeriod.ThreeYears:
      case DateRangePeriod.ThisQuarter:
      case DateRangePeriod.PastQuarter: {
        range = this.dateHelper.convertDateRangePeriodToDateRange(value);
        break;
      }

      case DateRangePeriod.AllTime: {
        range = this.dateHelper.convertDateRangePeriodToDateRange(DateRangePeriod.AllTime);
        if (minDate) {
          range.start = minDate;
        }

        break;
      }

      default:
        return true;
    }

    const startInside = this.dateHelper.isBetween(range.start, minDate, maxDate);
    const endInside = this.dateHelper.isBetween(range.end, minDate, maxDate);

    return startInside && endInside;
  }
}
