import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { contentAnimation, dropDownAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { takeUntil } from 'rxjs/operators';
import { CustomDatepickerType, InputCustomTokens } from '../input.types';

enum ToggleValue {
  Today = 1,
  Tomorrow,
  Yesterday,
}

enum CustomDateMode {
  Past = 1,
  Future,
}

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation, dropDownAnimation],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-custom-datepicker',
    '[@dropDownAnimation]': '_dropDownAnimationState',
  },
})
export class CustomDatepickerComponent extends BaseObject implements OnInit {
  @Input() public selected: CustomDatepickerType;

  @Output() public closeEvent = new EventEmitter<CustomDatepickerType>();

  public _dropDownAnimationState: 'enter-dropdown' | 'void' = 'void';
  public readonly ToggleValue = ToggleValue;
  public readonly CustomDateMode = CustomDateMode;

  public toggleControl = new UntypedFormControl();
  public today = this.dateHelper.startOf('Today');
  public customDateModeControl = new UntypedFormControl(CustomDateMode.Future);
  public dayCountControl = new UntypedFormControl(20);

  constructor(private dateHelper: DateHelper) {
    super();

    this.listenToToggleChange();
  }

  public ngOnInit(): void {
    this._dropDownAnimationState = 'enter-dropdown';

    if (
      this.selected?.length === 2 &&
      this.selected[0] === InputCustomTokens.ADD_DAY_TO_NOW &&
      Number.isInteger(this.selected[1])
    ) {
      this.dayCountControl.setValue(Math.abs(this.selected[1]));

      if (this.selected[1] < 0) {
        this.customDateModeControl.setValue(CustomDateMode.Past);
      }
    }
  }

  private listenToToggleChange(): void {
    this.toggleControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((toggleValue: ToggleValue) => {
        switch (toggleValue) {
          case ToggleValue.Today: {
            this.dayCountControl.setValue(0);
            this.customDateModeControl.setValue(CustomDateMode.Future);
            break;
          }

          case ToggleValue.Tomorrow: {
            this.dayCountControl.setValue(1);
            this.customDateModeControl.setValue(CustomDateMode.Future);
            break;
          }

          case ToggleValue.Yesterday: {
            this.dayCountControl.setValue(1);
            this.customDateModeControl.setValue(CustomDateMode.Past);
            break;
          }
        }

        this.updateSelected();
      });
  }

  public _onCancelClick(): void {
    this.closeEvent.next(null);
  }

  public _onApplyClick(): void {
    this.updateSelected();

    this.closeEvent.next(this.selected);
  }

  private updateSelected(): void {
    let step: number = 0;

    switch (this.customDateModeControl.value) {
      case CustomDateMode.Future:
        step = this.dayCountControl.value;
        break;

      case CustomDateMode.Past:
        step = -this.dayCountControl.value;
        break;

      default:
        break;
    }

    this.selected = [InputCustomTokens.ADD_DAY_TO_NOW, step];
  }
}
