import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { FileData } from '../uploader.types';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-file-list',
  },
})
export class FileListComponent<FileType = unknown> {
  @HostBinding('class.app-file-list-new') public hostClassNewDesign = false;

  @Input() public label: string = 'Drag and drop or select file';
  @Input() public showLabel: boolean = true;
  @Input() public multiple: boolean = true;
  @Input() public format: 'file' | 'base64' = 'file';
  @Input() public accept: string;
  @Input() public showButton: boolean = true;
  @Input() public buttonText: string = 'Add Files';
  @Input() public disabled: boolean = false;
  @Input() public canEditName: boolean = true;
  @Input() public backgroundTemplate: TemplateRef<unknown>;
  @Input() public errorMessage: string;

  @Input({ required: true })
  public set files(value: FileData<FileType>[]) {
    this.files$.next(value);
  }

  @Input()
  public set isNewDesign(isNewDesign: boolean) {
    this.hostClassNewDesign = isNewDesign;
  }

  @Input()
  public set hideBackground(value: boolean) {
    this.hideBackground$.next(value);
  }

  @Output() public changeFiles = new EventEmitter<FileData<FileType>[]>();

  public _trackByIndex = (index: number): number => index;
  public nameControl = new UntypedFormControl();
  public editableIndex: number;
  public files$ = new BehaviorSubject<FileData<FileType>[]>([]);
  public hideBackground$ = new BehaviorSubject<boolean>(false);

  @ViewChild(FileUploaderComponent) public fileUploaderComponent: FileUploaderComponent<File>;

  constructor() {}

  public _onStartEdit(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.editableIndex = index;
    this.nameControl.patchValue(this.files$.value[index].name);
  }

  public _onSuccessEdit(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.editableIndex = null;
    const newArr = [...this.files$.value];
    newArr[index] = { ...newArr[index], name: this.nameControl.value };
    this.changeFiles.emit(newArr);
  }

  public _onDeclineEdit(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.editableIndex = null;
  }

  public _onDeleteFile(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.files$.value.splice(index, 1);

    this.fileUploaderComponent.setFiles(this.files$.value as FileData<File>[]);
    this.changeFiles.emit(this.files$.value);
  }

  public _onChangeData(files: FileData<File | string>[]): void {
    if (this.disabled) {
      return;
    }

    if (this.accept) {
      const validFiles: FileData<FileType>[] = [];

      for (const file of files) {
        if (this.accept.includes(this.getFileExtensionFromName(file.name))) {
          validFiles.push(file as FileData<FileType>);
        }
      }

      this.files$.next(this.multiple ? [...this.files$.value, ...validFiles] : validFiles);
    } else {
      this.files$.next(
        this.multiple
          ? [...this.files$.value, ...(files as FileData<FileType>[])]
          : (files as FileData<FileType>[]),
      );
    }

    this.changeFiles.emit(this.files$.value);
  }

  public _onDndClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName !== 'INPUT') {
      this.fileUploaderComponent?.click();
    }
  }

  private getFileExtensionFromName(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  }
}
