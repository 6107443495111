import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { contentAnimation, dropDownAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { takeUntil } from 'rxjs/operators';
import { CustomDaterangePickerType, InputCustomTokens } from '../input.types';

enum ToggleValue {
  Last7Days = 1,
  Next7Days,
}

@Component({
  selector: 'app-custom-daterange-picker',
  templateUrl: './custom-daterange-picker.component.html',
  styleUrls: ['./custom-daterange-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation, dropDownAnimation],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-custom-daterange-picker app-bg-main',
    '[@dropDownAnimation]': '_dropDownAnimationState',
  },
})
export class CustomDaterangePickerComponent extends BaseObject implements OnInit {
  @Input() public selected: CustomDaterangePickerType;
  @Input() public maxDate: Date;

  @Output() public closeEvent = new EventEmitter<CustomDaterangePickerType>();

  public readonly ToggleValue = ToggleValue;
  public today = this.dateHelper.startOf('Today');

  public toggleControl = new UntypedFormControl();
  public rangeDaysGroup = new UntypedFormGroup({
    fromDay: new UntypedFormControl(-10),
    toDay: new UntypedFormControl(10),
  });
  public _dropDownAnimationState: 'enter-dropdown' | 'void' = 'void';

  constructor(private dateHelper: DateHelper) {
    super();

    this.listenToToggleChange();
  }

  public ngOnInit(): void {
    this._dropDownAnimationState = 'enter-dropdown';

    if (
      this.selected?.length === 3 &&
      this.selected[0] === InputCustomTokens.ADD_DAY_TO_NOW &&
      Number.isInteger(this.selected[1]) &&
      Number.isInteger(this.selected[2])
    ) {
      this.rangeDaysGroup.patchValue({
        fromDay: this.selected[1],
        toDay: this.selected[2],
      });
    }
  }

  private listenToToggleChange(): void {
    this.toggleControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((toggleValue: ToggleValue) => {
        switch (toggleValue) {
          case ToggleValue.Last7Days: {
            this.rangeDaysGroup.patchValue({
              fromDay: -7,
              toDay: 0,
            });
            break;
          }

          case ToggleValue.Next7Days: {
            this.rangeDaysGroup.patchValue({
              fromDay: 0,
              toDay: 7,
            });
            break;
          }
        }

        this.updateSelected();
      });
  }

  private updateSelected(): void {
    this.selected = [
      InputCustomTokens.ADD_DAY_TO_NOW,
      parseInt(this.rangeDaysGroup.controls.fromDay.value),
      parseInt(this.rangeDaysGroup.controls.toDay.value),
    ];
  }

  public _onCancelClick(): void {
    this.closeEvent.next(null);
  }

  public _onApplyClick(): void {
    this.updateSelected();

    this.closeEvent.next(this.selected);
  }
}
