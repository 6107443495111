import { AppDatePipe } from '@shared/pipes/date.pipe';
import { MatDateFormats, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { AppInjector } from './app-injector';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';

export const DEFAULT_DATE_INPUT_FORMAT = 'CUSTOM_FORMAT_KEY';

export const DEFAULT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: DEFAULT_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: DEFAULT_DATE_INPUT_FORMAT,
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Injectable()
export class GlobalDateAdapter extends NativeDateAdapter {
  private DATE_PIPE = new AppDatePipe();
  private dateHelper: DateHelper;
  private userSettingsState: UserSettingsState;

  constructor(
    @Inject(MAT_DATE_LOCALE) public matDateLocale: string,
    platform: Platform,
  ) {
    super(matDateLocale, platform);

    this.dateHelper = AppInjector.Injector.get(DateHelper);
    this.userSettingsState = AppInjector.Injector.get(UserSettingsState);
  }

  public deserialize(date: string | Date): Date | null {
    const result = this.dateHelper.parse(date);

    return result || super.deserialize(date);
  }

  public parse(date: string | Date): Date | null {
    const result = this.dateHelper.parse(date, this.userSettingsState.getDateFormat());

    return result;
  }

  public format(date: Date, displayFormat: string): string {
    switch (displayFormat) {
      case DEFAULT_DATE_INPUT_FORMAT:
        return this.DATE_PIPE.transform(this.dateHelper.parse(date));

      case DEFAULT_DATE_FORMATS.display.monthYearLabel:
        return this.DATE_PIPE.transform(this.dateHelper.parse(date));

      default:
        return super.format(date, displayFormat);
    }
  }
}
