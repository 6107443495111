import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SettingsState } from '@shared/states/settings.state';
import { LazyData } from '@ui/table/table-types';
import {
  BusinessEventDTO,
  CertificateCreateDTO,
  ChangedValueDTO,
  CorporateActionRawDTO,
  ExcelTableRequestDTO,
  FieldDTO,
  FrontDatesShortRollingFieldsDTO,
  InstrumentDividendDTO,
  InstrumentDTO,
  InstrumentExternalSearchDTO,
  InstrumentFieldsFullDTO,
  InstrumentInPositionDTO,
  InstrumentSearchDTO,
  RollingFieldDTO,
  SearchRequestDTO,
} from '@shared/dto/gateway-secured/models';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { CouponDTO } from '@shared/dto/positions/coupon-dto';
import { FileLoaderService } from '@shared/helpers/file-loader.service';
import { GovernanceFieldsDTO } from '@shared/dto/gateway-secured/governance-fields-dto';
import { InstrumentNameDTO } from '@shared/dto/gateway-secured/instrument-name-dto';
import { DataWithError } from '@shared/types/data-with-error';
import { InstrumentPerformanceDTO } from 'app/dialogs/position/position-dialog.types';

export interface InstrumentPrice {
  date: Date;
  value: number;
}

@Injectable({
  providedIn: 'root',
})
export class InstrumentsService {
  constructor(
    private fileLoaderService: FileLoaderService,
    private http: HttpClient,
    private settingsState: SettingsState,
    private dateHelper: DateHelper,
  ) {}

  public getOne(id: string): Observable<InstrumentDTO> {
    return this.http
      .get<InstrumentDTO>(`${this.settingsState.apiPath}/instruments/${id}`)
      .pipe(catchError(() => of(null)));
  }

  public getMarketsNames(instrumentsIds: string[]): Observable<InstrumentNameDTO[]> {
    return this.http
      .get<InstrumentNameDTO[]>(`${this.settingsState.apiPath}/instruments/names`, {
        params: {
          ids: instrumentsIds,
        },
      })
      .pipe(catchError(() => of(null)));
  }

  public getOneWithEmptyFields(id: string): Observable<InstrumentFieldsFullDTO> {
    return this.http
      .get<InstrumentFieldsFullDTO>(
        `${this.settingsState.apiPath}/instruments/${id}/with-empty-fields`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getInstrumentInPosition(id: string): Observable<InstrumentDTO> {
    return this.http
      .get<InstrumentDTO>(`${this.settingsState.apiPath}/instrument-in-position/${id}`)
      .pipe(catchError(() => of(null)));
  }

  public getInstrumentsWithPagination(
    request: SearchRequestDTO,
  ): Observable<LazyData<InstrumentDTO>> {
    const url = `${this.settingsState.apiPath}/instruments/search`;

    return this.http.post<InstrumentDTO[]>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<InstrumentDTO>;
      }),
      catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData)),
    );
  }

  public getInstrumentsByIds(ids: string[]): Observable<InstrumentSearchDTO[]> {
    return this.http
      .put<InstrumentSearchDTO[]>(`${this.settingsState.apiPath}/instruments-by-ids`, ids)
      .pipe(catchError(() => of([])));
  }

  public getExternalInstruments(
    searchText: string,
  ): Observable<DataWithError<InstrumentSearchDTO[]>> {
    return this.http
      .get<InstrumentExternalSearchDTO[]>(`${this.settingsState.apiPath}/instruments-external`, {
        params: {
          filter: searchText || '',
        },
      })
      .pipe(
        map((result) => {
          return { data: result.map((p) => p.instrument), error: null };
        }),
        catchError((err: HttpErrorResponse) => of({ data: null, error: err })),
      );
  }

  public getExternalInstrumentFromBloomberg(
    bloombergId: string,
    assetType: string,
  ): Observable<DataWithError<InstrumentDTO>> {
    return this.http
      .post<InstrumentDTO>(`${this.settingsState.apiPath}/instruments`, null, {
        params: {
          bloombergId: bloombergId,
          assetType: assetType,
        },
      })
      .pipe(
        map((result) => {
          return { data: result, error: null };
        }),
        catchError((err: HttpErrorResponse) => of({ data: null, error: err })),
      );
  }

  public getIncomeCalendarWithPagination(
    request: SearchRequestDTO,
  ): Observable<LazyData<CouponDTO>> {
    const url = `${this.settingsState.apiPath}/instrument-coupons/search`;

    return this.http.post<CouponDTO>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<CouponDTO>;
      }),
      catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData)),
    );
  }

  public getCorporateActionsWithPagination(
    request: SearchRequestDTO,
  ): Observable<LazyData<CorporateActionRawDTO>> {
    const url = `${this.settingsState.apiPath}/corporate-action-raw/search`;

    return this.http.post<CorporateActionRawDTO>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<CorporateActionRawDTO>;
      }),
      catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData)),
    );
  }

  public getDividendsWithPagination(
    request: SearchRequestDTO,
  ): Observable<LazyData<InstrumentDividendDTO>> {
    const url = `${this.settingsState.apiPath}/instrument-dividends/search`;

    return this.http.post<InstrumentDividendDTO>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<InstrumentDividendDTO>;
      }),
      catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData)),
    );
  }

  public getMissedInstrumentFieldsFromBloomberg(
    instrumentId: string,
  ): Observable<InstrumentSearchDTO> {
    return this.http
      .get(
        `${this.settingsState.apiPath}/load-missed-instrument-search-fields?instrumentId=${instrumentId}`,
      )
      .pipe(catchError(() => of(null)));
  }

  public reCalculate(id: string): Observable<boolean> {
    return this.http
      .get(
        `${this.settingsState.apiPath}/instruments/recalculate-observation-dates-statuses?instrumentId=${id}`,
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public updateFields(data: {
    fields?: FieldDTO[];
    rollingFields?: RollingFieldDTO[];
  }): Observable<boolean> {
    return this.http.put(`${this.settingsState.apiPath}/instruments/fields`, data).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public getPrices(
    instrumentId: string,
    isPrivate: boolean = false,
  ): Observable<InstrumentPrice[]> {
    return this.http
      .get<Record<string, number>>(
        `${this.settingsState.apiPath}/instruments/${instrumentId}/prices`,
        {
          params: {
            isPrivate: `${isPrivate}`,
          },
        },
      )
      .pipe(
        map((prices) => {
          return Object.keys(prices).map((date) => {
            return {
              date: this.dateHelper.parse(date),
              value: prices[date],
            } as InstrumentPrice;
          });
        }),
      );
  }

  public searchInstruments(
    filter: string,
    assetType: InstrumentSearchDTO.AssetTypeEnum = null,
  ): Observable<DataWithError<InstrumentSearchDTO[]>> {
    const params: { filter?: string; assetType?: InstrumentSearchDTO.AssetTypeEnum } = {};

    if (filter) {
      params.filter = `${filter}`;
    } else {
      params.filter = '';
    }

    if (assetType) {
      params.assetType = assetType;
    }

    return this.http
      .get<InstrumentSearchDTO[]>(`${this.settingsState.apiPath}/instruments-internal`, {
        params: params,
      })
      .pipe(
        map((data) => {
          return {
            data: data,
            error: null,
          };
        }),
        catchError((err) => of({ data: [], error: err })),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getInstrumentsTiny(instrumentId: string): Observable<Record<string, any>> {
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<Record<string, any>>(`${this.settingsState.apiPath}/instruments-tiny/${instrumentId}`)
        .pipe(catchError(() => of({})))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getInstrumentsSpTiny(instrumentId: string): Observable<Record<string, any>> {
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<Record<string, any>>(
          `${this.settingsState.apiPath}/instruments-sp-tiny/${instrumentId}`,
        )
        .pipe(catchError(() => of({})))
    );
  }

  public getInstrumentsInPosition(instrumentIds: string[]): Observable<InstrumentInPositionDTO[]> {
    return this.http.get<InstrumentInPositionDTO[]>(
      `${this.settingsState.apiPath}/instruments-in-position`,
      {
        params: {
          instrumentIds: instrumentIds,
        },
      },
    );
  }

  public createExternalInstrument(instrument: InstrumentSearchDTO): Observable<InstrumentDTO> {
    return this.http
      .post<InstrumentDTO>(`${this.settingsState.apiPath}/instruments`, null, {
        params: {
          bloombergId: instrument.bloombergId,
          assetType: instrument.assetType,
        },
      })
      .pipe(catchError(() => of(null)));
  }

  public createExternalInstrumentCertificate(
    certificate: CertificateCreateDTO,
  ): Observable<DataWithError<InstrumentDTO>> {
    return this.http
      .post<InstrumentDTO>(`${this.settingsState.apiPath}/instruments/certificate`, certificate)
      .pipe(
        map((result) => {
          return { data: result, error: null };
        }),
        catchError((err: HttpErrorResponse) => of({ data: null, error: err })),
      );
  }

  public getInstrumentPrices(id: string): Observable<{ [key: string]: number }> {
    return this.http.get<{ [key: string]: number }>(
      `${this.settingsState.apiPath}/instruments/${id}/prices`,
    );
  }

  public getAccrued(instrumentId: string, date?: string): Observable<number> {
    if (!date) {
      date = this.dateHelper.format(
        this.dateHelper.add(this.dateHelper.startOf('Today'), 2, 'BusinessDays'),
      );
    }

    const url = `${this.settingsState.apiPath}/instruments/${instrumentId}/rolling-fields/Accrued`;

    return this.http.get<number>(url, {
      params: {
        date: date,
        externalLoadIfNoData: 'true',
      },
    });
  }

  public getPrice(instrumentId: string, date?: string): Observable<number> {
    if (!date) {
      date = this.dateHelper.format(new Date());
    }

    const url = `${this.settingsState.apiPath}/instruments/${instrumentId}/rolling-fields/Price`;

    return this.http.get<number>(url, {
      params: {
        date: date,
        externalLoadIfNoData: 'true',
      },
    });
  }

  public getRollingFieldValue(
    id: string,
    valueDate: string,
    field: string,
    externalLoadIfNoData: boolean = true,
  ): Observable<number> {
    return this.http.get<number>(
      `${this.settingsState.apiPath}/instruments/${id}/rolling-fields/${field}?date=${valueDate}&externalLoadIfNoData=${externalLoadIfNoData}`,
    );
  }

  public getRollingFields(
    instrumentId: string,
    startDate: Date,
    endDate: Date,
  ): Observable<FrontDatesShortRollingFieldsDTO> {
    const url = `${this.settingsState.apiPath}/instruments/${instrumentId}/rolling-fields`;

    return this.http
      .get<FrontDatesShortRollingFieldsDTO>(url, {
        params: {
          startDate: this.dateHelper.format(startDate),
          endDate: this.dateHelper.format(endDate),
        },
      })
      .pipe(catchError(() => of(null)));
  }

  public loadExcel(fileName: string, searchRequest: ExcelTableRequestDTO): void {
    const url = `${this.settingsState.apiPath}/instruments/generate-excel-table-new`;

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName);
  }

  public setVpId(instrument: InstrumentDTO): Observable<InstrumentDTO> {
    return this.http
      .put<InstrumentDTO>(
        `${this.settingsState.apiPath}/instruments/${instrument.id}/set-vp-id/${instrument.vpId}`,
        instrument,
      )
      .pipe(catchError(() => of(null)));
  }

  public updateInstrumentStringProperties(
    fieldValues: Record<string, string>,
    instrumentId: string,
  ): Observable<boolean> {
    return this.http
      .put<Record<string, string>>(
        `${this.settingsState.apiPath}/update-instrument-string-properties/${instrumentId}`,
        fieldValues,
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public getMarketsIds(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/market-ids`)
      .pipe(catchError(() => of([])));
  }

  public refreshLatestDataByFields(
    instrumentIds: string[],
    fieldMetaNames: string[],
  ): Observable<BusinessEventDTO> {
    return this.http
      .put<BusinessEventDTO>(
        `${this.settingsState.apiPath}/latest-by-fields-instruments`,
        {},
        {
          params: {
            bloombergIds: instrumentIds,
            fieldMetaNames: fieldMetaNames,
          },
        },
      )
      .pipe(catchError(() => of(null)));
  }

  public refreshHistoricalDataByFields(
    instrumentIds: string[],
    fieldMetaNames: string[],
    startDate: string,
    endDate: string,
  ): Observable<BusinessEventDTO> {
    return this.http
      .put<BusinessEventDTO>(
        `${this.settingsState.apiPath}/historical-by-fields-instruments`,
        {},
        {
          params: {
            bloombergIds: instrumentIds,
            endDate: endDate,
            fieldMetaNames: fieldMetaNames,
            startDate: startDate,
          },
        },
      )
      .pipe(catchError(() => of(null)));
  }

  public getGovernanceFields(id: string): Observable<GovernanceFieldsDTO> {
    return this.http
      .get<InstrumentDTO>(`${this.settingsState.apiPath}/governance-fields/instruments/${id}`)
      .pipe(catchError(() => of(null)));
  }

  public updateGovernanceFields(
    data: GovernanceFieldsDTO,
    instrumentId: string,
  ): Observable<GovernanceFieldsDTO> {
    return this.http
      .put(`${this.settingsState.apiPath}/governance-fields/instruments/${instrumentId}`, data)
      .pipe(
        map((data) => data || {}),
        catchError(() => of(null)),
      );
  }

  public getInstrumentsPerformance(
    instrumentIds: string[],
    range: { start: string; end: string },
  ): Observable<InstrumentPerformanceDTO> {
    return this.http
      .get<InstrumentPerformanceDTO>(`${this.settingsState.apiPath}/instruments/performance`, {
        params: {
          startDate: range.start,
          endDate: range.end,
          instrumentIds: instrumentIds,
        },
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  public getInstrumentsIsinChanges(instrumentId: string): Observable<ChangedValueDTO> {
    return this.http
      .get<ChangedValueDTO>(
        `${this.settingsState.apiPath}/instruments/${instrumentId}/isin-changes`,
      )
      .pipe(catchError(() => of(null)));
  }
}
